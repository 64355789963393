import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SelectWithSearchHookForm } from '../../../../../components/SelectWithSearch/SelectWithSearchHookForm';
import { useDebounce, useGetCitiesUkrposhta } from '../../../../../hooks';
import { transformSelectOptionsForUkrposhta } from '../../../../../utils';

export const UkrPoshtaCityAutoCompleteFieldHookForm = ({
  name,
  cityId,
  cityName,
  cityFullName,
  onChange,
}) => {
  const [options, setOptions] = useState([]);
  const [citySearchValue, setCitySearchValue] = useState(cityFullName);

  const { debouncedValue: debouncedSearchValue, isDebouncing } =
    useDebounce(citySearchValue);

  const [getCities, { loading }] = useGetCitiesUkrposhta({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (debouncedSearchValue?.length > 2) {
      getCities({
        variables: {
          search: debouncedSearchValue,
        },
        onCompleted: (response) => {
          setOptions(
            transformSelectOptionsForUkrposhta({
              data: response?.getCitiesUkrposhta,
              value: 'id',
              label: 'fullName',
              info: 'name',
            }),
          );
        },
      });
    }
  }, [debouncedSearchValue.length]);

  const setSearchHandler = (val) => {
    return val ? setCitySearchValue(val) : setCitySearchValue('');
  };

  const initOption =
    cityId && cityFullName && cityName
      ? { value: cityId, label: cityFullName, info: cityName }
      : null;

  return (
    <SelectWithSearchHookForm
      width='100%'
      pl={0}
      pr={0}
      onChange={onChange}
      initialOption={initOption}
      setSearchValue={setSearchHandler}
      isLoading={loading || isDebouncing}
      onMenuOpen={() => {}}
      options={options}
      name={name}
      label='app.city'
      placeholderIntlId='app.enterValueMin3'
      isUkrposhta
    />
  );
};

UkrPoshtaCityAutoCompleteFieldHookForm.propTypes = {
  name: PropTypes.string,
  cityName: PropTypes.string,
  cityFullName: PropTypes.string,
  cityId: PropTypes.number,
  onChange: PropTypes.func,
};
