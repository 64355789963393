import { useFormikContext } from 'formik';
import React from 'react';

import { QueryMultiSelectWithSearch } from '../../../../components/QuerySelectWithSearch/QueryMultiSelectWirhSearch';
import { GET_PRICE_TAGS } from '../../../../graphql';
import { transformSelectOptions } from '../../../../utils';

const PriceTags = () => {
  const { values, setFieldValue } = useFormikContext();
  const { productPriceTags } = values;

  const initialOption = transformSelectOptions({
    data: productPriceTags.map(({ id, name }) => ({
      id,
      name,
    })),
    value: 'id',
    label: 'name',
  });

  const handleCreateOption = (createdOption) => {
    setFieldValue('productPriceTags', [...productPriceTags, { name: createdOption }]);
  };

  const onChangeTagSelecte = (option) => {
    setFieldValue(
      'productPriceTags',
      option.map(({ value, label }) => ({ id: value, name: label })),
    );
  };

  return (
    <QueryMultiSelectWithSearch
      isMulti
      width={'100%'}
      label='app.priceTags'
      valueProperty={'id'}
      labelProperty={'name'}
      placeholderIntlId='app.enterValue'
      fieldName='productPriceTags'
      initialOption={initialOption}
      graphqlName={'priceTags'}
      graphql={GET_PRICE_TAGS}
      onChange={onChangeTagSelecte}
      isCreateble
      handleCreateOption={handleCreateOption}
      mb={0}
      pr={0}
      pl={0}
    />
  );
};

export default PriceTags;
