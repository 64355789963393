import { MaterialIcons } from '@expo/vector-icons';
import { Field, Formik, useFormikContext } from 'formik';
import { Box, Flex, Icon, Image as NBImage, Spinner, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import Cropper from 'react-easy-crop';

import { BoxWrapper, Button, Typography } from '../../../../components';
import { API_HOST } from '../../../../constants';
import { useNotificationsContext } from '../../../../context';
import stylesGlobal from '../../../../globalStyles';
import { useAddFormattedPhoto, useIntl } from '../../../../hooks';
import { LocalStorage } from '../../../../services';
import { colors } from '../../../../theme';
import { styles } from './styles';

const SUPPORTED_FORMAT_PHOTO = ['.jpg', '.png', '.jpeg', '.webp'];

const UploadImageWthDNDContext = ({ photoFormats, loadingProduct }) => {
  const intl = useIntl();
  const { showNotification } = useNotificationsContext();
  const photo = useRef({ id: photoFormats?.id || '', url: '' });
  const pathCropPhoto = photoFormats?.formats?.find((el) => el.format === 'smallCrop')
    .pathWithTime
    ? `${API_HOST}/${
        photoFormats?.formats?.find((el) => el.format === 'smallCrop').pathWithTime
      }`
    : '';
  const pathPhoto = photoFormats?.path ? `${API_HOST}/${photoFormats?.path}` : '';

  const { setFieldValue } = useFormikContext();
  const [imageSrc, setImageSrc] = useState(pathPhoto);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(pathCropPhoto);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);

  const [addFormatedPhoto] = useAddFormattedPhoto(photo);

  const addImage = async (file, imgURL) => {
    setLoading(true);
    const token = await LocalStorage.getValue('token');
    const ext = file.name.substr(file.name.lastIndexOf('.'));
    if (!SUPPORTED_FORMAT_PHOTO.includes(ext)) {
      showNotification({
        title: 'app.error',
        message: 'app.notSupportedFormatPhoto',
        type: 'error',
      });
    } else if (file.size > 2097152) {
      showNotification({
        title: 'app.error',
        message: 'app.notSupportedSizePhoto',
        type: 'error',
      });
    } else {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name.split('.')[0]);
      formData.append('alt', file.name.split('.')[0]);
      try {
        const response = await fetch(process.env.REACT_APP_UPLOAD_URL, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        photo.current.id = responseData.id;
        setImageSrc(imgURL);
        showNotification({ message: 'photo.uploaded' });
      } catch (error) {
        showNotification({
          title: 'app.error',
          message: error.message,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const onDrop = (item) => {
    if (item) {
      const file = item.files[0];
      const imgURL = URL.createObjectURL(file);
      addImage(file, imgURL);
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item);
        }
      },
      //eslint-disable-next-line
      canDrop(item) {
        return true;
      },

      collect: (monitor) => {
        //eslint-disable-next-line
        const item = monitor.getItem();
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [onDrop],
  );
  //eslint-disable-next-line
  const isActive = canDrop && isOver;

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImage);

      addFormatedPhoto({
        variables: {
          input: {
            photoId: photo.current.id,
            format: 'smallCrop',
            top: croppedAreaPixels.y,
            left: croppedAreaPixels.x,
            width: croppedAreaPixels.width,
            height: croppedAreaPixels.height,
          },
        },
      });
      setFieldValue('photoId', +photo.current.id);
    } catch (e) {
      console.error(e);
    }
    //eslint-disable-next-line
  }, [imageSrc, croppedAreaPixels]);

  return (
    <Formik>
      {() => (
        <BoxWrapper
          style={stylesGlobal.form}
          w={448}
          h={imageSrc && !loading && !croppedImage ? 598 : 548}
        >
          {!loadingProduct ? (
            <Box>
              <Typography mb={6} intlId='app.productPhoto' />

              {!imageSrc && !loading && (
                <View ref={drop}>
                  <NBImage
                    color={'blue'}
                    width={400}
                    height={400}
                    mb={6}
                    source={require('../../../../assets/photo.png')}
                    alt={intl.formatMessage({ id: 'app.productPhoto' })}
                  />
                  <Flex justify={'flex-end'}>
                    <Box w={130} mr={6}>
                      <Typography
                        style={styles.textInfoUploadFile}
                        intlId='app.infoUploadFile'
                      />
                    </Box>
                    <label htmlFor='file'>
                      <Button intlId='app.uploadFile' w={136} size='sm' />
                    </label>
                    <input
                      // eslint-disable-next-line
                      style={{ display: 'none' }}
                      type='file'
                      accept='.jpg, .png, .jpeg, .webp'
                      name={`file`}
                      id={`file`}
                      onChange={async (e) => {
                        let file = await e.target.files[0];

                        let imgURL = URL.createObjectURL(file);
                        addImage(file, imgURL);
                      }}
                    />
                  </Flex>
                </View>
              )}
              {loading && (
                <View>
                  <Flex style={styles.download} paddingY='lg'>
                    <Spinner size='lg' />
                    <Typography
                      style={styles.text}
                      intlId='app.uploadingPhoto'
                      variant='heading'
                    />
                  </Flex>
                  <Flex justify={'flex-end'}>
                    <Button disabled intlId='app.uploadFile' w={136} size='sm' />
                  </Flex>
                </View>
              )}
              {imageSrc && !croppedImage && !loading && (
                <>
                  <BoxWrapper width={'400px'} height={'400px'}>
                    <Cropper
                      image={!loading && imageSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </BoxWrapper>
                  <Box width={400}>
                    <input
                      type='range'
                      min={1}
                      max={3}
                      step={0.1}
                      value={zoom}
                      onInput={(e) => {
                        onZoomChange(e.target.value);
                      }}
                    ></input>
                  </Box>
                  <Flex mt={6} justify={'flex-end'}>
                    <Button
                      w={150}
                      size='sm'
                      intlId='app.remove'
                      mr={6}
                      backgroundColor={colors.danger}
                      onPress={() => setImageSrc('')}
                    />

                    <Button
                      intlId='app.save'
                      w={136}
                      size='sm'
                      onPress={showCroppedImage}
                    />
                  </Flex>
                </>
              )}
              {imageSrc && croppedImage && (
                <View ref={drop}>
                  <Box>
                    <NBImage
                      width={400}
                      height={400}
                      source={croppedImage}
                      resizeMode='contain'
                      alt={intl.formatMessage({ id: 'app.productPhoto' })}
                    />
                    <Icon
                      position={'absolute'}
                      left={374}
                      name='edit'
                      as={MaterialIcons}
                      size={26}
                      color={colors.primary.default}
                      bgColor={colors.white}
                      onPress={() => setCroppedImage(null)}
                    />
                  </Box>

                  <Flex mt={6} justify={'flex-end'}>
                    <Button
                      w={150}
                      intlId='app.remove'
                      size='sm'
                      mr={6}
                      backgroundColor={colors.danger}
                      onPress={() => {
                        setImageSrc('');
                        setCroppedImage(null);
                        setCrop({ x: 0, y: 0 });
                        setZoom(1);
                        photo.current.id = '';
                        photo.current.url = '';
                        setFieldValue('photoId', +photo.current.id);
                      }}
                    />
                    <label htmlFor='file'>
                      <Button intlId='app.uploadFile' w={136} size='sm' />
                    </label>
                    <Field
                      // eslint-disable-next-line
                      style={{ display: 'none' }}
                      type='file'
                      name={`file`}
                      id={`file`}
                      onChange={async (e) => {
                        setImageSrc('');
                        setCroppedImage(null);
                        setCrop({ x: 0, y: 0 });
                        setZoom(1);
                        photo.current.id = '';
                        photo.current.url = '';
                        setFieldValue('photoId', +photo.current.id);
                        let file = await e.target.files[0];
                        let imgURL = URL.createObjectURL(file);
                        addImage(file, imgURL);
                      }}
                    />
                  </Flex>
                </View>
              )}
            </Box>
          ) : (
            <View>
              <Flex style={styles.download} paddingY='lg'>
                <Spinner size='lg' />
              </Flex>
            </View>
          )}
        </BoxWrapper>
      )}
    </Formik>
  );
};

const UploadImage = ({ photoFormats, loadingProduct }) => (
  <DndProvider backend={HTML5Backend}>
    <UploadImageWthDNDContext
      photoFormats={photoFormats}
      loadingProduct={loadingProduct}
    />
  </DndProvider>
);

export default UploadImage;

const createImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
};

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 *
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
async function getCroppedImg(imageSrc, pixelCrop = 0, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const safeArea = Math.max(image.width, image.height) * 2;

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
  );

  // As Base64 string
  return canvas.toDataURL('image/jpeg');
}

UploadImage.propTypes = {
  photoFormats: PropTypes.object,
  loadingProduct: PropTypes.bool,
};
UploadImageWthDNDContext.propTypes = {
  photoFormats: PropTypes.object,
  loadingProduct: PropTypes.bool,
};
