import { StyleSheet } from 'react-native';

import { colors } from '../../../../theme';

export const styles = StyleSheet.create({
  download: {
    backgroundColor: colors.gray.background,
    borderColor: colors.gray.border,
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    flexDirection: 'column',
    height: 400,
    justifyContent: 'center',
    marginBottom: 24,
    width: 400,
  },
  text: {
    color: colors.gray.main,
    fontSize: 14,
    marginTop: 16,
  },
  textInfoUploadFile: {
    color: colors.gray.main,
    fontSize: 10,
    lineHeight: 12,
  },
});
